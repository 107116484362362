body{
  font-family: $openlight;
  font-size: 18px;
  color: rgb(51,51,51);
}

h1,h2,h3,h4{
  font-family: $openbold;
  text-transform: uppercase;
  color: $primary;
}

h1.page-header{
  font-size: 36px;
  @media (min-width: $screen-sm-min){
    font-size: 48px;
  }
  text-align: center;
  position: relative;
  border-bottom: none;
  padding-bottom: 40px;
}


h1.page-header::after{
  content: '';
  border-bottom: 5px solid $secondary;
  width: 200px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.md-header{
  font-size: 36px;
  text-align: center;
  position: relative;
  border-bottom: none;
  padding-bottom: 40px;
  margin-top: 70px;
  margin-bottom: 40px;
}

.md-header::after{
  content: '';
  border-bottom: 5px solid $secondary;
  width: 200px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

a{
  color: $blue;
}

.node .field-item p:first-child,
.intropara{
  font-family: $openlight;
  font-size: 30px;
}

.bigtxt{
  font-size: 60px;
}