// Default variables.
@import 'default-variables';
// Bootstrap Framework.
@import '../bootstrap/assets/stylesheets/bootstrap';
// Base-theme overrides.
@import 'overrides';
// Components
@import 'components/navigation';
@import 'components/typography';
@import 'components/views';

.main-container{
  max-width: 2000px;
}

a:hover{
  text-decoration: none;
}

#headwrap{
  background-color: $primary;
}

.navbar-header{
  clear: both;
  @media(min-width: $screen-md-min){
    clear: none;
  }
}

.navbar .logo{
  padding: 0;
  margin: 0;
  float: left;
  padding: 0 15px;
  @media(min-width: $screen-sm-min){
    padding: 15px;
  }
  @media(min-width: $screen-md-min){
    padding: 0 15px;
    float: none;
  }
}

#logomax{
  display: none;
  @media(min-width: $screen-md-min){
    display: inline;
  }
}
#logomin{
  display: inline;
  @media(min-width: $screen-md-min){
    display: none;
  }
}

@media (min-width: $screen-md-min) {
  header#navbar > .row{
    display: flex;
    align-items: center;
  }
}

.front iframe{
  max-width: 100%;
}

.ncibutt{
  @include button-variant(#fff, $primary, $primary);
  border-radius: 4em;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  margin: 0 auto;
}

.ncibutt-md{
  @extend .ncibutt;
  border-radius: 2em;
  width: 250px;
  height: auto;
  text-transform: uppercase;
  font-family: $openbold;
  font-size: 20px;
  @media (min-width: $screen-md-min) {
    width: 400px;
    font-size: 30px;
  }
}

#block-system-main-menu ul.nav{
  display: flex;
  flex-flow: row wrap;
}

#block-views-nivo-slider-block .view-content{
  display: block;
}

#views-nivo-slider-nivo_slider-block{
  background-size: cover !important;
  img{
    object-fit: cover !important;
  }
  .nivo-slice{
    background-size: cover !important;
  }
}

.front #block-system-main{
  margin-bottom: 20px;
}

.node .pagetitle{
  color: #999;
}

.node .field-item p:first-child,
.view-header p:first-child{
  font-family: $openlight;
  font-size: 30px;
}

.node-station .field-item p:first-child{
  font-size: inherit;
}

.field-name-body{
  margin-bottom: 40px;
}

.field-name-field-date{
  font-family: $gothicbold;
  font-size: 18px;
  color: #999;
  margin-bottom: 20px;
}

.region-top-bar{
  padding: 15px;
  order:  1;
  flex: 1;
  color: #fff;
  font-family: $openbold;
  font-size: 18px;
  a{
    color: #fff;
  }
  float: right;
  text-align: right;
  @media(min-width: $screen-md-min){
    float: none;
  }
}

.dldicons{
  #twitter{
    background-color: #00aced;
  }
  #facebook{
    background-color: #3b579d;
  }
  #linkedin{
    background-color: #0077b5;
  }
  #instagram{
    background-color: #ca0080;
  }
  #pound, #login{
    background-color: $primary;
  }
}

#mainwrap .container-fluid{
  padding: inherit;
}

//bootstrap carousel
.carousel{
  max-width: 2000px;
  margin: 0 auto;
}
.carousel-fade .carousel-inner .item {
  opacity: 0;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
}
.carousel-fade .carousel-inner .active {
  opacity: 1;
}
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}
.carousel-fade .carousel-control {
  z-index: 2;
}

.carousel-indicators{
  display: none;
  @media (min-width: $screen-xs-min){
    display: block;
  }
}

.carousel-indicators li,
.carousel-indicators li.active{
  width: 20px;
  height: 20px;
  background-color: $primary;
}

.carousel-indicators li.active{
  background-color: $secondary;
}

.carousel-caption {
  left: 0;
  padding-bottom: 30px;
  background-color: rgba($primary, .9);
  width: 599px;
  bottom: 0;
  display: none;
  h3{
    color: #fff;
  }
  @media (min-width: $screen-sm-min){
    display: block;
  }
  @media (min-width: $screen-md-min){
    left: 0;
    bottom: 0;
    margin: 0;
    h3{
      font-size: 40px;
    }
    p{
      font-family: $openlight;
      font-size: 19px;
    }
  }
  @media (min-width: $screen-lg-min){
    h3{
      font-size: 60px;
    }
    p{
      font-family: $openlight;
      font-size: 24px;
    }
  }
}

.dldicons .icon{
  padding: 8px;
  border-radius: 3em;
  margin-right: 5px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 0 $green;
  transition: all ease 0.8s;
  svg{
    fill: #fff;
    stroke: #fff;
    width: 22px;
    height: 22px;
    @media (min-width: $screen-lg-min){
      width: 32px;
      height: 32px;
    }
  }
  .icon:hover{
    box-shadow: inset 0 -100px 0 0 $green;
    border-color: $green;
    svg{
      fill: #fff;
      stroke: #fff;
    }
  }
}

#block-statbox-statbox{
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  svg{
    fill: rgba($primary, 1);
    grid-column: 1 / span 2;
    width: 100%;
      @media (min-width: $screen-md-min){
        grid-row: 1;
        height: 100px;
      }
    grid-row: 1;
  }


  .statcol{
    display: grid;
    text-align: center;
      @media (min-width: $screen-md-min){
        grid-template-columns: 50% 50%;
        text-align: left;
        column-gap: 10px;
      }
      .stattxt{
        grid-row: 2;
        grid-column: 1 / span 2;
        display: flex; 
        justify-content: center;
      }
      h1{
        font-size: 30px;
        margin-right: 20px;
          @media (min-width: $screen-md-min){
            font-size: 40px;
          }
          @media (min-width: $screen-lg-min){
            font-size: 60px;
          }
        }
      h2{
          font-size: 20px;
          color: rgba($primary, .5);
          @media (min-width: $screen-md-min){
            font-size: 20px;
          }
          @media (min-width: $screen-lg-min){
            font-size: 24px;
          }
      }
    
  }
}

//CTA block styles
.region-below-content3{
  max-width: 2000px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media(min-width: $screen-sm-min){
    flex-direction: row;
  }
  .block{
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 250px;
    font-family: $openbold;
    font-size: 36px;
    align-items: center;   
    text-transform: uppercase;
    display: flex;
    position: relative;
    flex: 1;
    padding: 12% 5%;
    margin: 2%;
    p{
      background-color: rgba($primary, .9);
      padding: 20px;
      margin: 0 auto;
      position: absolute;
      bottom: 0;
      left: 0;
      @media(min-width: $screen-sm-min){
     //   margin: 0 auto 0 20px;
      }
    }
    a{
      color: #fff;
      text-decoration: none;
    }
    a:hover{
      color: $blue;
    }
  }
}

.region-below-content4{
  max-width: 2000px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: $primary;
  color: #fff;
  text-align: center;
  h1, 
  .md-header{
    color: #fff;
  }
  #block-nciblocks-contact-details a{
    color: $secondary;
  }

  //contact
  #block-block-25,
  #block-nciblocks-contact-details{
    margin: 0;
    background-color: rgb(0, 0, 0);
    svg{
      fill: currentColor;
      stroke: currentColor;
      height: 50px;
      margin: 20px 0;
    }
    .glyphicon-envelope{
      font-size: 50px;
      margin: 20px 0;
    }
  }
  //video
  #block-block-40,
  #block-nciblocks-video{
    .ncibutt{
      border-color: #fff;
      border-width: 5px;
    }
  }
  @media(min-width: $screen-sm-min){
    flex-direction: row;
  }
  .block{
    width: 100%;
    margin: 0;
    padding-bottom: 40px;
    @media(min-width: $screen-sm-min){
      width: 33%;
      margin: 0 2%;
    }
  }
}

.region-below-content2{
  max-width: 2000px;
  background-color: $primary;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media(min-width: $screen-sm-min){
    flex-direction: row;
  }
}

#block-block-32,
#block-nciblocks-history{
  background-image: url(../images/historybg.jpg);
}
#block-block-33,
#block-nciblocks-volunteer{
  background-image: url(../images/volunteerbg.jpg);
}
#block-block-34,
#block-nciblocks-donate{
  background-image: url(../images/donatebg.jpg);
}

#block-nciblocks-facebook{
  background-color: #3b579d;
  color: #fff;
}
#block-nciblocks-twitter{
  background-color: #00aced;
  color: #fff;
  max-height: 340px;
  overflow: auto;
}
#block-block-38,
#block-nciblocks-findstation{
  color: #fff;
  flex: 1;
  padding: 40px;
  h1{
    color: #fff;
    font-size: 60px;
  }
  .ncibutt{
    border: 5px solid $secondary;
  }
}


.region-below-content{
    background-color: #f5f5f5;
    padding: 80px 40px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    @media(min-width: $screen-sm-min){
      grid-template-columns: repeat(3, 1fr);
    }
    @media(min-width: $screen-md-min){
      grid-template-columns: repeat(5, 1fr);
      gap: 40px;
    }
    gap: 20px;
    max-width: 2000px;
    margin: 0 auto;

  .block{
    text-align: center;
    @media(min-width: $screen-md-min){
      text-align: left;
    }
    h1{
      margin-top: 0;
      font-size: 60px;
    }
    .view{
      height: 100%;
    }
    .view-content{
      height: 100%;
      display: block;
      > div{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }
      img{
        margin: 0 auto;
      }
      @media(min-width: $screen-lg-min){
        img{
          width: 100%;
        }
      }
    }
  }
  .block:last-child{
    margin-right: 0;
  }
}
 


.field-name-field-link{
  text-align: center;
  margin-top: 40px;
  margin-bottom: 50px;
  position: relative; 
}
    
.block-title{
      border-bottom: 5px solid $secondary;
      margin-bottom: 30px;
      padding-bottom: 30px;
      text-align: center;
}

.region-hero{
  .block-bean{
    svg{fill: #fff; stroke: #fff;}
    .block-title{
      @extend .block-title;
      border-bottom: 1px solid rgba(255,255,255,.4);
    }
    text-align: center;
    .bean-call-to-action{
      text-align: left;
      font-size: 18px;
      font-family: $gothicbold;
    }   
    
    .field-name-field-link{
      @extend .field-name-field-link;
      border-bottom: 1px solid rgba(255,255,255,.4);
      a{
        background-color: $green;
        color: #fff;
        border: 1px solid rgba(255,255,255,.4);
      }
    }
  }
}

//shop categories menu
#block-uc-catalog-catalog ul{
  padding-left: 0;
  li{
    background-color: $primary;
    padding: 5px;
    margin-bottom: 2px;
    a{
      color: #fff;
    }
    a:hover{
      color: $secondary;
    }
    span.trail a{
      font-weight: normal;
      color: $secondary;
    }
  }
  li.viewcart{
    background-color: $secondary;
    a{
      color: $primary;
      font-family: $openbold;
    }
  }
  li.viewcart:hover{
    background-color: $primary;
    a{
      color: #fff;
    }
  }
}

.view-uc-catalog-terms table{
  width: 100%;
  border: 1px solid #ddd;
  font-weight: bold;
}

.node-product .field-name-body table,
.node-type-nci-members-only .views-field-body table{
  width: 100%;
  td{
    border: 1px solid #ddd;
    padding: 5px;
  }
}

.node-product .attributes .form-control{
  width: auto;
}
  

#secondfootwrap{
  background-color: #000;
  color: #fff;
  a{
    color: #fff;
  }
  #coslogo img{
    height: 38px;
    vertical-align: top;
    margin-left: 20px
  }
  .region-secondfooter{
    display: flex;
    justify-content: space-between;
    padding: 40px 0;
    flex-direction: column;
    @media(min-width: $screen-md-min){
      flex-direction: row;
    }
  }
}


.field-name-field-link-box{
  > .field-items{
    display:  grid;
    grid-template-columns: repeat(1,1fr);
    @media(min-width: $screen-sm-min){
      grid-template-columns: repeat(2,1fr);
    }
    @media(min-width: $screen-md-min){
      grid-template-columns: repeat(3,1fr);
    }
    grid-gap: 40px;
    margin-bottom: 40px;
    .content{ position: relative;}
    .field-name-field-link{
      position: absolute;
      margin-bottom: 0;
      background-color: rgba($primary, .9);
      bottom: 0;
      padding: 3%;
      .field-item a{
        color: #fff;
        font-family: $openbold;
        font-size: 36px;
        text-transform: uppercase;
      }
      .field-item a:after{
        content: '>';
        margin-left: 10px;
      }
      .field-item a:hover{
        color: $blue;
      }
    }
  }
}

//partners and sponsors pages
.partners{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  @media(min-width: $screen-sm-min){
    grid-template-columns: repeat(3, 1fr);
  }
  @media(min-width: $screen-md-min){
    grid-template-columns: repeat(5, 1fr);
  }
  grid-gap: 40px;
  .item a{
    height: 200px;
    display: block;
    img{
      max-height: 200px;
      margin: 0 auto;
    }
  }
}

//patrons
.patrons{
  display: grid;
  grid-gap: 40px;
  grid-template-columns: repeat(1, 1fr);
  @media(min-width: $screen-sm-min){
    grid-template-columns: repeat(2, 1fr);
  }
  @media(min-width: $screen-md-min){
    grid-template-columns: repeat(3, 1fr);
  }
  font-family: $openbold;
  font-size: 24px;
  text-transform: uppercase;
  color: $blue;
}

//Volunteer form
#webform-client-form-28796,
#webform-client-form-31528{
  margin-bottom: 40px;
  > div{
    display: grid;
    grid-template-columns: repeat(1,1fr);
    @media(min-width: $screen-sm-min){
      grid-template-columns: repeat(2,1fr);
    }
    @media(min-width: $screen-md-min){
      grid-template-columns: repeat(4,1fr);
    }
    gap: 40px;
  }
  .form-control{
    border: 2px solid rgba($primary,.5);
    border-radius: 2em;
  }
  .form-required{
    color: red;
  }
  .webform-progressbar{
    display: none;
  }
  @media(min-width: $screen-md-min){
    .webform-component--mobile,
    .webform-component--email,
    .webform-component--email-again,
    .webform-component--preferred-nci-station{
      grid-column: 1/1;
    }
    .webform-component--address{
      grid-row: 3/span 4;
      grid-column: 2;
      textarea{
        height: 400px;
      }
    }
    .webform-component--unspent-criminal-offences{
      grid-column: 3;
      grid-row: 3/span 4;
      textarea{
        height: 400px;
      }
    }
    .webform-component--other-information{
      grid-column: 4;
      grid-row: 3/span 4;
      textarea{
        height: 400px;
      }
    }
  }
  .help-block{
    font-size: 18px;
    color: inherit;
  }
  #edit-submitted-contact{
    display: flex;
    justify-content: space-between;
    .form-item{
      margin: 0;
      position: relative;
    }
    label{
      display: flex;
      align-items: center;
    }
    input{
      visibility: hidden;
    }
    input ~ .checkmark{
      background-color: transparent;
      border: 2px solid rgba($primary, 0.5);
      border-radius: 4em;
      padding: 15px;
      display: inline-block;
      margin-left: 5px;
    }
    input:checked ~ .checkmark{
      background-color: $secondary;     
    }    
  }
  button.webform-submit{
    background-color: $primary;
    width: 100%;
    text-transform: uppercase;
    font-family: $openbold;
    font-size: 24px;
    border: none;
    border-radius: 2em;
  }
}

#webform-client-form-28798,
#webform-client-form-31529{
  @extend #webform-client-form-28796;
  @media(min-width: $screen-md-min){
    .webform-component--email-address,
    .webform-component--subject,
    .webform-component--category{
      grid-column: 1/1;
    }
    .webform-component--message{
      grid-column: 2;
      grid-row: 1/span 4;
      .form-textarea-wrapper,
       textarea{
        height: 100%;
      }
    }
    .webform-component--message-help{
      grid-row: 1/span 2;
      grid-column: 3;
    }
    .webform-component--contact{
      grid-row: 3;
      grid-column: 3;
    }
    .webform-component--privacy{
      grid-column: 4;
      grid-row: 1/span 2;
    }
    .form-actions{
      grid-column: 4;
      grid-row: 4;
    }
  }
}

#block-nciblocks-contact-us-details{
  color: $primary;
  font-family: $openbold;
  font-size: 48px;
  svg{
    fill: currentColor;
    stroke: currentColor;
  }
  .contact-us-wrap{
    display: grid;
    grid-template-columns: repeat(1,1fr);
    a{
      color: $primary;
    }
    @media(min-width: $screen-md-min){
      grid-template-columns: repeat(3,1fr);
    }
    .glyphicon-envelope{
      font-size: 2.5em;
    }
    grid-gap: 40px;
    text-align: center;
    > p{
      .smallertxt{
        width: 100%;
        @media(min-width: $screen-md-min){
          width: auto;
        }
      }
    }
  }
  .smallertxt{
    font-size: .5em;
    color: rgba($primary, .5);
    display: inline-block;
  }
  #reqinftxt{
    text-align: center;
    font-size: 18px;
    font-family: $openlight;
    border-top: 5px solid $secondary;
    margin: 40px auto;
    @media(min-width: $screen-md-min){
      width: 30%;
    }
    padding: 40px 0;
  }
}

// backend-login page
.backend-login-wrap{
  display: grid;
  grid-template-columns: repeat(1,1fr);
  @media(min-width: $screen-sm-min){
   grid-template-columns: repeat(3,1fr);
  }
  text-align: center;
  .control-label{
    font-weight: normal;
  }
  button.form-submit{
    background-color: $primary;
    width: 100%;
    text-transform: uppercase;
    font-family: $openbold;
    font-size: 24px;
    border: none;
    border-radius: 2em;
  }
  #edit-submit--2{
    background-color: rgba($primary, .5);
    color: #fff;
  }
  input{
    background-color: transparent;
    border: 2px solid rgba($primary, 0.5);
    border-radius: 4em;
    padding: 20px;
    display: inline-block;
    margin-left: 5px;
    margin-bottom: 40px;
    margin-top: 20px;
  }
  .page-header,
  .md-header{
    margin-top: 0;
  }
  > div{
    padding: 40px
  }
  .col1{
    @media(min-width: $screen-md-min){
      margin-left: -20px;
    }
  }
  .col1,
  .col2{
    background-color: #f5f5f5;
  }
  .col3{
    h1{
      color: rgba($primary, .5);
      font-size: 30px;
      @media(min-width: $screen-lg-min){
        font-size: 36px;
      }
    }
  }
}

//Station home page
.node-station{
  .group-label{
    font-family: $openbold;
  }
  .field-name-field-station-pic,
  iframe{
    float: none;
    @media(min-width: $screen-sm-min){
      float: right;
    }

  }
  iframe{
    clear: right;
    margin-top: 20px;
  }
  .opcap{
    clear: both;
    .group-communications{
      @media(min-width: $screen-sm-min){
        column-count: 2;
      }
    }
    .field-name-field-weather-station-url,
    .field-name-field-nci-website{
      a{
        font-size: 18px;
        font-family: $openbold;
      }
    }
  }
  .field-name-field-radio-listening-watch-on-c,
  .field-name-field-radio-coastal-channels-in-{
    .field-items .field-item{
      display:inline;
      margin-right: 10px;
    }
  }
  .statinfowrap{
    background-color: #f5f5f5;
    margin: 40px -20px 0;
    padding: 40px 20px 0;
    .statinfo{
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      @media(min-width: $screen-sm-min){
        grid-template-columns: repeat(2, 1fr);
      }
      @media(min-width: $screen-md-min){
        grid-template-columns: repeat(5, 1fr);
      }
      grid-gap: 40px;
    }
    .statinfo-head h1{
      margin-top: 0;
    }
    .field-name-field-email-address{
      a{
        font-size: 18px;
        font-family: $openbold;
      }
    }
    .statcontacts .group-label{
      margin-top: 20px;
    }
    .statcontacts .group-label:first-child{
      margin-top: 0;
    }
    .statloc{
      @media(min-width: $screen-md-min){
        grid-column: 2;
        grid-row: 2/span2;
      }
    }
    .statmap{
      @media(min-width: $screen-md-min){
        grid-column: 3/span3;
        grid-row: 2/span2;
      }
    }
    .backbuttwrap{
      @media(min-width: $screen-md-min){
        grid-column: 1;
      }
    }
  }
  .adminonly-fields{
    margin: 20px 0;
  }
}

//News page

.newswrap{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  @media(min-width: $screen-sm-min){
      grid-template-columns: repeat(2, 1fr);
      }
  @media(min-width: $screen-md-min){
      grid-template-columns: repeat(5, 1fr);
  }
  .views-field-field-picture img{
    width: 100%;
  }
  grid-gap: 40px;
  background-color: #f5f5f5;
  margin: 0 -20px;
  padding: 40px;
}

//News detail
.node-type-story{
  article{
    margin-bottom: 40px;
    .field-name-field-picture{
      float: right;
    }
    .newsback{
      display: block;
      clear: both;
    }
    @media(min-width: $screen-sm-min){
      .field-name-field-picture{
        margin-left: 20px;
        margin-bottom: 20px;
      }
    }
  }
}

@media(max-width: $screen-sm-min){
  #book-navigation-33023{
    .dropdown-menu{
      max-width: 350px;
    }
  }
}