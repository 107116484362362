// Theme Customizations to Navigations //

#navbar{
  max-width: 2000px;
  nav{
    display: flex;
  }
}


#navbar > .row{
  text-align: center;
  padding-top: 20px;
  @media(min-width: $screen-sm-min){
    padding-top: 0;
 //   padding-bottom: 6px;
    display: flex;
    align-items: center;
  }
  @media(min-width: $screen-md-min){
    padding-bottom: 0;
    display: inline;
  }
}

#navbar-collapse{
  padding: 0;
}
.navbar{
  margin-bottom: 0;
}
.navbar-default .navbar-toggle .icon-bar{
  width: auto;
  background-color: $secondary;
}

.navbar-default .navbar-nav>li>a{
  color: #fff;
}

.navbar-default{
  border: none;
  background-color: transparent;
  li a{
    font-family: $open;
    color: #fff;
  }

  .caret{
    display: none;
  }

  .navbar-toggle {
    border-color: rgb(252, 205, 0);
    color: rgb(255, 222, 0);
    background-color: $primary;
  }

  .navbar-toggle:hover, 
  .navbar-toggle:focus {
    background-color: lighten($primary, 10%);
  }

  .navbar-nav > .open > a:hover,
  .navbar-nav > .open > a{
    background-color: $primary;
  }

  .dropdown-menu,
  .dropdown-menu > li > a:hover{
    background-color: $primary;
    text-align: center;
  }

  .navbar-nav .open .dropdown-menu > li > a{
    padding: 3px 20px;
  }

  .navbar-nav li a{
    text-transform: capitalize;
    padding: 10px 7px;
    @media(min-width: $screen-md-min){
      padding: 10px 15px;
    }
  }

  .navbar-nav > .active > a,
  .navbar-nav > .active > a:hover,
  .navbar-nav li .active{
    color: $secondary !important;
    background-color: transparent;
  }

  .navbar-nav > li:hover::after,
  .navbar-nav > li.active::after{
    border-bottom: 5px solid $secondary;
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -45px;
  }

  .sm-collapsible li:hover::after,
  .sm-collapsible li.active::after{
    border-bottom: none;
  }

  .active-trail ul{
    padding-left: 20px;
  }
}

.navbar-nav{
  margin: auto;
}

.navbar-nav > li{
  border-right: 1px solid #e1e1e1;
}

.navbar-nav > li.last{
  border-right: 0;
}

.navbar-nav > li > a{
  padding-top: 5px;
  padding-bottom: 5px;
}


@media(min-width: $screen-sm-min){
  .navbar-nav > li.expanded > a::after{
    content: '\e259';
    position: absolute;
    font-family: 'Glyphicons Halflings';
    color: #fff;
    bottom: -20px;
    left: 0;
    right: 0;
  }
  .navbar-nav > li.expanded{
    text-align: center;
  }
  .navbar-default .active-trail ul{
    padding-left: 0;
  }
}

@media(min-width: $screen-sm-min){
  .navbar-default .navbar-nav > .active > a{
    background-color: transparent;
  }

  .navbar-nav > li.open{
    position: initial;
  }

  .navbar-nav li a:hover{
    color: $secondary !important;
  }

  .navbar-nav > li.open > ul{
    display: flex !important;
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;

    top: 124px !important;
    
    position: absolute;
    left:0 !important;
    justify-content: space-evenly !important;
    background-color: rgba($primary, .7);
    padding: 20px;
  }
  
  .navbar-nav > li > ul{
    display: none !important;
  }

  .navbar-nav > li > ul > li{
    border-left: 1px solid #fff;
  }
  .navbar-nav > li > ul > li.first{
    border-left: none;
  }

  .navbar-nav > li.expanded > ul{
    display: none;
  }

  .navbar-nav li.open > a{
    color: $secondary !important;
  }

  .navbar-nav li.open ul a:hover{
    background-color: transparent;
    color: $secondary;
  }

  //3rd level
  .navbar-nav li ul ul{
    display: flex;
    flex-direction: column;
    margin-top: 0 !important;
    margin-left: 0 !important;
    max-width: 0 !important;
    min-width: 0 !important;
  }
  .navbar-nav li ul li.open a{
    background-color: transparent !important;
  }
}

//2nd menu at desktop size
@media(min-width: $screen-md-min){
  .navbar-nav > li.open > ul{
    top: 123px !important;
  }
  #navbar > .row{
    padding-bottom: 6px;
  }
}

 //hide desktop nav until md size
 nav > .navbar-nav{
  display: none;
  @media(min-width: $screen-sm-min){
    display: block;
  }
}

#block-smartmenus-smartmenus-1{
  #smartmenus-1{
    padding: 20px;
  }
  display: block;
  a.active{
    color: $secondary;
  }
  li ul{
    padding-left: 20px;
  }
  @media(min-width: $screen-sm-min){
    display: none;
  }
}

//Footer Menu
#block-menu-menu-footer-menu ul.menu{
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  @media(min-width: $screen-md-min){
    flex-direction: row;
    margin: 0;
  }
  justify-content: space-between;
  li{
    border-right: none;
    @media(min-width: $screen-md-min){
      border-right:  1px solid;
    }
    a{
      padding: 0 15px;
    }
    a:hover{
      background-color: transparent;
      text-decoration: underline;
    }
  }
  li:last-child{
    border-right: none;
  }
}


//Station menu
.view-station-content{
  .view-content{
    display: block;
    ul{
      display: flex;
      flex-flow: row wrap;
      list-style: none;
      margin: 0;
      padding: 20px;
      justify-content: center;
      background-color: rgb(19, 38, 77);
      li{
        border-right: 1px solid #fff;
        margin-right: 10px;
        padding-right: 10px;
        margin-top: 10px;
        a{
          color: #fff;
          font-family: $openlight;
          font-weight: normal;
          font-size: 18px;
        }
        a:hover{
          color: $secondary;
        }
      }
      li:last-child{
        border-right: none;
      }
    }
  }

}