.nci-grid .view-content{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: minmax(auto, 220px);
    grid-gap: 40px;
    justify-items: center;
    align-items: start;
    .views-field-title{
        margin-top: 20px;
    }
}

table td.views-field-actions{
    display: flex;
}
  
.views-field-field-photo{
    margin-bottom: 20px;
}
  
.view-tweets .view-content{
display: block !important;
    .views-field-screen-name{
        font-family: $gothicbold;
        color: $blue;
    }
    ul{padding-left: 0;}
    li{
        list-style: none;
        background: url("/sites/all/themes/dld/images/tweeticon.png") no-repeat;
        padding-left: 60px;
    }
}

#block-views-map-of-stations-block-1{
    flex: 2;
    padding: 0 20px;
    @media(min-width: $screen-sm-min){
        padding: 0;
    }
    .view-content{
        display: block;
    }
}

.view-map-of-stations{
    .view-content{
        display: block;
    }
}


.view-content{
    .views-field-title a{
        font: bold 24px $openbold;
        color: $primary;  
    }
    a:hover{
        text-decoration: none;
        color: $blue;
    }
}

//station-map page and filters
.page-station-map,
.page-station-list{
    .main-container{
        background: $primary;
        .views-exposed-form{
            color: #fff;
        }
        h1.page-header{
            color: #fff;
            font-size: 60px;
        }
    }
    #edit-taxonomy-vocabulary-1-tid-wrapper{
        padding: 0;
        font-family: $openbold;
        font-size: 20px;
        text-transform: uppercase;
        .filtcol-first{
            margin-right: 20px;
        } 
        @media(min-width: $screen-md-min){
            font-size: 30px;
            display: flex;
            .filtcol-first{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-right: 80px;
            }
        }
        .filter-title{
            font-size: 48px;
            margin-top: 0;
        }
        .mapicon{
            height: 30px;
            fill: #fff;
        }
        #edit-taxonomy-vocabulary-1-tid{
            @media(min-width: $screen-sm-min){
                column-count: 2;
            }
            .radio input[type="radio"]{
                position: relative;
                margin-left: 10px;
                visibility: hidden;
            }
            label.control-label{               
                padding-left: 20px;
                margin-bottom: 30px;
                @media(min-width: $screen-md-min){
                    padding-left: 40px;
                    margin-bottom: 50px;
                }
            }
            label.control-label::before {
                background: rgb(13, 28, 71);
                content: '';
                position: absolute;
                width: 30px;
                height: 30px;
                border: 3px solid rgb(255, 255, 255);
                @media(min-width: $screen-md-min){
                    width: 50px;
                    height: 50px;
                    border: 5px solid rgb(255, 255, 255);
                }
                border-radius: 4em;
                left: 0;
            }
            .highlight label.control-label::before {
                background: $secondary;
            }
        }
        .mapswitch{
            display: inline-flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 40px;
            .mapicon{
                height: 40px;
                font-size: 18px;
                border: 5px solid $secondary;
                padding: 8px;
                box-sizing: content-box;
                border-radius: 4em;
                width: 40px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                margin: 0 20px;
            }
           a .mapicon,
           a .maptxt,
           a .listtxt{
                color: rgba(255,255,255,.5);
                border-color: rgba(255,255,255,.5);
                fill: rgba(255, 255, 255, .5);
            }
            a:hover .mapicon,
            a:hover .maptxt,
            a:hover .listtxt{
                color: rgba(255,255,255,1);
                border-color: $secondary;
                fill: rgba(255, 255, 255, 1);
            }
        }
    }
}

.page-station-list{
    overflow-x: hidden;
   .view-map-of-stations .view-content{
        background-color: rgb(255, 255, 255);
        width: 100vw;
        position: relative;
        left: 50%;
        margin-left: -50vw;
        padding: 20px;
        right: 50%;
        margin-right: -50vw;
        display: grid;
        grid-auto-rows: minmax(auto, 100px);
        .md-header{
            margin-top: 0;
            width: 100%;
        }
        .views-row{
            text-align: center;
        }
        .views-field-title a{
            color: $blue;
        }
        @media(min-width: $screen-sm-min){
            grid-template-columns: repeat(3, 1fr);
            .md-header{
                grid-column: span 3;
            }
            .views-row{
                text-align: left;
            }          
        }
        @media(min-width: $screen-md-min){
            grid-template-columns: repeat(5, 1fr);
            .md-header{
                grid-column: span 5;
            }
        }
    }
    @media(min-width: $screen-sm-min){
        .view-content{
            justify-items: left;
        }
    }

     .mapswitch{
        a{
            display: inline-flex;
            align-items: center;
        }
    }
}

//Annual Statistics
.annual-stats{
    .view-content{
        grid-auto-rows: auto;
        justify-items: normal;
        h1{
            a{
                color: $primary;
            }
        }
        .views-row {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            @media(min-width: $screen-sm-min){
                grid-template-columns: repeat(2, 1fr);
            }
            @media(min-width: $screen-md-min){
                grid-template-columns: repeat(4, 1fr);
            }
            /* @media(min-width: $screen-lg-min){
                grid-template-columns: repeat(4, 1fr);
            } */
            column-gap: 40px;
            .views-field {
                display: flex;
                flex-direction: column;
                @media(min-width: $screen-sm-min){
                    flex-direction: row;
                }
                align-items: center;
                .views-label {
                    order: 2;
                    font-family: $openbold;
                    font-size: 24px;
                    text-transform: uppercase;
                    color: rgba($primary,.5);
                    padding-left: 20px;
                }
                .field-content {
                    order: 1;
                    font-family: $openbold;
                    font-size: 40px;
                    @media(min-width: $screen-md-min){
                        font-size: 60px;
                    }
                }
            }
        }
    }
}

.incident-summary{
    .view-content{
        display: block;
    }
}

#block-views-page-hero-image-block{
    .view-page-hero-image{
        position: relative;
        width: fit-content;
        padding: 0;
    }
    .view-content{
        display: block;
    }
    .views-field-field-page-header{
        img{
            margin: 0 auto;
        }
    }
    .view-footer{
        color: rgb(255, 255, 255);
        background-color: rgba($primary,.9);
        font-family: $openlight;
        font-size: 20px;
        padding: 10px;
        margin:  0;
        @media(min-width: $screen-md-min){
            border-bottom: none;
            position: absolute;
            bottom: 0;
        //    left: 20px;
            font-size: 32px;
            width: 40%;
            margin: 0;
            padding: 20px;
        }
        @media(min-width: $screen-lg-min){
         font-size: 48px;
        }
        
    }
}

//trustees and national officers
.view-trustees{
    .view-content{
        @media(min-width: $screen-sm-min){
         grid-template-columns: repeat(2, 1fr);
        }
        @media(min-width: $screen-md-min){
         grid-template-columns: repeat(4, 1fr);
        }
        @media(min-width: $screen-lg-min){
         grid-template-columns: repeat(5, 1fr);
        }
        grid-auto-rows: auto;
        .views-field-title{
            font-family: $openbold;
            margin-top: 20px;
        }
        .views-field-field-job-title{
            margin-bottom: 20px;
        }
    }
}

//News section

//Latest news block
.view-display-id-block_2{
    margin: 0 -20px;
    padding: 40px;
    .view-content{
        grid-auto-rows: inherit;
        @media(min-width: $screen-sm-min){
            grid-template-columns: repeat(2, 1fr);
           }
        @media(min-width: $screen-md-min){
            grid-template-columns: repeat(5, 1fr);
        }
        .views-field-field-picture img{
            width: 100%;
        }
    }
}



//All news page
.page-news .view-display-id-page_1{
    padding: 40px;
}

.view-fpnews{
    .view-content{
        grid-auto-rows: inherit;

        @media(min-width: $screen-sm-min){
            grid-template-columns: repeat(2, 1fr);
            }
        @media(min-width: $screen-md-min){
            grid-template-columns: repeat(5, 1fr);
        }
        .views-field-field-picture img{
            width: 100%;
        }
        .morebox{
            background-color: #f5f5f5;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            .nci-pager{
                display: grid;
                list-style: none;
                padding: 0;
                .pager-previous{
                    grid-column: 1;
                    grid-row: 1;
                }
                .pager-next{
                    grid-column: 2;
                    grid-row: 1;
                }
                .pager-current{
                    grid-column: 1 / span 2;
                    grid-row: 2;
                    text-align: center;
                    font-size: 22px;
                    text-transform: uppercase;
                    color: #333;
                    font-family: $openbold;
                    margin-top: 40px;
                }
                a{
                    color: $secondary;
                }
            }
        }
    }
            .nci-pager{
                display: none;
            }
}