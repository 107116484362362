/**
 * Default Variables.
 *
 * Modify this file to provide default Bootstrap Framework variables. The
 * Bootstrap Framework will not override any variables defined here because it
 * uses the `!default` flag which will only set their default if not already
 * defined here.
 *
 * You can copy existing variables directly from the following file:
 * ./THEMENAME/bootstrap/assets/stylesheets/bootstrap/_variables.scss
 */

// Set the proper directory for the Bootstrap Glyphicon font.
$icon-font-path: '../bootstrap/assets/fonts/bootstrap/';


//grid
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width:         40px !default;
$container-large-desktop:      (1960px + $grid-gutter-width) !default;

$screen-lg-min: 2000px;
$screen-md-min: 1400px;
$screen-sm-min: 900px;

$screen-lg: 1800px !default;
$screen-md: 1102px !default;
$screen-sm: 868px !default;

$container-lg: 1980px;
$container-md: 1380px;
$container-sm: 880px;

// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint:     $screen-sm-min !default;

//colours
$orange: #E17F25;
$green: #78a22f;
$darkgreen: #2c7251;
$blue: #0062bf;

$primary: #0d1c47;
$secondary: #fdd101;
$lightgrey: #eee;

//fonts
$open: opensans, arial, sans-serif;
$openbold:  opensansbold, arial, sans-serif;
$openextrabold:  opensansextrabold, arial, sans-serif;
$openlight:  opensanslight, arial, sans-serif;

$gothic: centurygothic, arial, sans-serif;
$gothicbold: centurygothicbold, arial, sans-serif;
$robotoreg: robotoreg, arial, sans-serif; 
$robotolight: robotolight, arial, sans-serif;

@font-face {
  font-family: 'opensans';
  src: url(../fonts/OpenSans-Regular.ttf);
}
@font-face {
  font-family: 'opensansbold';
  src: url(../fonts/OpenSans-Bold.ttf);
}
@font-face {
  font-family: 'opensansextrabold';
  src: url(../fonts/OpenSans-ExtraBold.ttf);
}
@font-face {
  font-family: 'opensanslight';
  src: url(../fonts/OpenSans-Light.ttf);
}

@font-face {
  font-family: 'centurygothic';
  src: url(../fonts/GOTHIC.TTF);
}

@font-face {
  font-family: 'centurygothicbold';
  src: url(../fonts/GOTHICB.TTF);
}

@font-face {
  font-family: 'robotoreg';
  src: url(../fonts/robotoreg.ttf);
}

@font-face {
  font-family: 'robotolight';
  src: url(../fonts/robotolight.ttf);
}